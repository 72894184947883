  .Dropdown-root {
      position: relative;
      height: 2rem;
  }
  
  .BigSize .Dropdown-control {
    height: 3rem;
    font-size: 10pt;
    padding: 10px;
  }

  .Dropdown-control {
    position: relative;
    overflow: hidden;
    border: 2px solid rgb(166, 166, 166);
  
    box-sizing: border-box;
    cursor: default;
    outline: none;
  
    transition: all 200ms ease;
    
    font-size: 10pt;
    color: rgb(51, 51, 51);
    font-weight: normal;
    font-style: normal;
    text-align: left;
    line-height: 1.5;
    padding: 5px;
    height: 2rem;
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    width: 100%;
    margin-left: 1rem;
  }

  .readOnly .Dropdown-control {
    border: 0;
    margin-left: 0;
  }

  .BigSize .Dropdown-placeholder {
    line-height: 1.5;
  }

  .isRequired .Dropdown-control {
    background-color: rgb(255, 255, 0);
  }

  .isRequired.isRequiredValue .Dropdown-control {
    background-color: rgb(255, 229, 175);
  }

  .Dropdown-control:focus {
      border: 4px solid rgb(166, 166, 166) !important;
      height: 2rem;
  }

  .readOnly .Dropdown-control:focus{
    margin-left: 0;
    border: 0 !important;
    height: 1rem;
  }

  .Dropdown-control:active {
      border: 4px solid rgb(166, 166, 166) !important;
      height: 2rem;
      width: calc(93% + 4px);
  }
  .readOnly .Dropdown-control:active{
    border: 0 !important;
    height: 2rem;
  }

  .Dropdown-control:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    border: 2px solid rgb(51, 91, 3);
    background-color: rgb(212, 212, 212);
  }

  .isRequired.readOnly .Dropdown-control:hover {
    background-color: rgb(255, 255, 0) !important;
    margin-left: 0;
  }
  
  .readOnly .Dropdown-control:hover{
    border: 0 !important;
    height: 2rem;
    background-color: white;
    box-shadow: 0 0 0;
  }


  .Dropdown-arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-top: -ceil(2.5);
    position: absolute;
    right: 10px;
    top: 3px;
  }
  .readOnly .Dropdown-arrow {
    border: 0;
  }
  
  .BigSize .Dropdown-arrow {
    right: 16px;
    top: 10px;
    padding: 7px;
  }
  
  .Dropdown-menu {
    font-size: 9pt;
    background-color: rgb(255, 255, 255);
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    /* top: 100%; */
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
    text-align: left;
    color: rgba(51, 51, 51, 1);
    margin-left: 16px;
  }

  .isRequired .Dropdown-menu {
    background-color: rgb(255, 255, 0);
  }
  
  .isRequired.isRequiredValue .Dropdown-menu {
    background-color: rgb(255, 229, 175);
  }
  .isRequired.readOnly .Dropdown-menu {
    margin-left: 0;
  }

  .MenuOpensOnTop .Dropdown-menu{
    top: auto;
    bottom: 100%;
  }

  .Dropdown-menu .Dropdown-group > .Dropdown-title{
    padding: 8px 10px;
    color: rgba(51, 51, 51, 1);
    font-weight: bold;
    text-transform: capitalize;
  }
  
  .Dropdown-option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
    display: block;
    padding: 8px 10px;
    min-height: 2rem;
  }
  
  .Dropdown-option:last-child {
    border-bottom-right-radius: 2px;
     border-bottom-left-radius: 2px;
  }
  
  .Dropdown-option.is-selected {
    
    background-color: rgba(73, 130, 5, 1);
    color: #333;
  }
  .Dropdown-option:hover {
    background-color: rgba(212, 212, 212, 1);
    color: #333;
  }

  .Dropdown-option:active {
    background-color: rgba(73, 130, 5, 1);
  }

  .Dropdown-option:focus {
    background-color: rgba(73, 130, 5, 1);
  }
  
  .Dropdown-noresults {
    box-sizing: border-box;
    color: #ccc;
    cursor: default;
    display: block;
    padding: 8px 10px;
  }
  
  .Dropdown-placeholder.is-selected {
    width: 93%;
    overflow: hidden;
  }
  .Dropdown-option.Dropdown-placeholder {
    width: 93%;
  }